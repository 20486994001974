import { useEffect, useRef } from "react"
/* eslint-disable */
const useEffectSkipComponentMount = (func, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}

export default useEffectSkipComponentMount
