import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import get from "lodash/get"

const InstaIcon = ({ className }) => {
  const { allContentfulPersistentContent } = useStaticQuery(graphql`
    query {
      allContentfulPersistentContent {
        nodes {
          instagramLink
        }
      }
    }
  `)
  const instaLink = get(
    allContentfulPersistentContent,
    "nodes[0].instagramLink",
    undefined
  )
  if (!instaLink) return

  return (
    <a href={instaLink} target="_blank" rel="noreferrer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        version="1.1"
        className={`fill-current ${className}`}
      >
        <path
          d="M8 3a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h8a5 5 0 0 0 5-5V8a5 5 0 0 0-5-5zm10 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-6 2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 2a3 3 0 1 0 .002 6.002A3 3 0 0 0 12 9z"
          id="surface1"
        />
      </svg>
    </a>
  )
}

export default InstaIcon
