export const COLORS = {
  text: {
    light: "#1a1b1d",
    dark: "#fff",
  },
  background: {
    light: "#FFF",
    dark: "#1a1b1d",
  },
  contrastText: {
    light: "rgb(255, 255, 255)",
    dark: "rgb(26, 27, 29)",
  },
  contrastBackground: {
    light: "rgba(26, 27, 29, 0.95)",
    dark: "rgba(255, 255, 255, 0.95)",
  },
  generalCursor: {
    light: `url(/black-circle.svg) 10 10, auto`,
    dark: `url(/white-circle.svg) 10 10, auto`,
  },
  focusCursor: {
    light: `url(/black-circle-focus.svg) 10 10, auto`,
    dark: `url(/white-circle-focus.svg) 10 10, auto`,
  },
  infoCursor: {
    light: `url(/info.svg) 11 11, auto`,
    dark: `url(/info-contrast.svg) 11 11, auto`,
  },
}

export const COLOR_MODE_KEY = "color-mode"
export const INITIAL_COLOR_MODE_CSS_PROP = "--initial-color-mode"
