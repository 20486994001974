import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"

const Layout = ({ children, toggleIsGrid, navInstruction, isGrid }) => {
  //bring in persistent content for header and footer from Contentful
  const { contentfulPersistentContent } = useStaticQuery(graphql`
    query {
      contentfulPersistentContent {
        instagramLink
        navLinks {
          ... on Node {
            ... on ContentfulLinkExternal {
              id
              link
              linkTitle
            }
          }
        }
      }
    }
  `)

  return (
    <div className="relative">
      <Header
        navLinks={contentfulPersistentContent.navLinks}
        instagramLink={contentfulPersistentContent.instagramLink}
        toggleIsGrid={toggleIsGrid}
        navInstruction={navInstruction}
        isGrid={isGrid}
      />
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  toggleIsGrid: PropTypes.func,
  navInstruction: PropTypes.func,
  isGrid: PropTypes.bool,
}
export default Layout
