import React from "react"

function TriangleRight({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      fill="none"
      viewBox="0 0 11 11"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        d="M11 5.5L0 11V0l11 5.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default TriangleRight
