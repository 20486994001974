import React from "react"

function SquareIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      className={`fill-current ${className}`}
    >
      <path d="M0 0H14V14H0z"></path>
    </svg>
  )
}

export default SquareIcon
