import React from "react"

function GridIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      className={`fill-current ${className}`}
    >
      <path d="M0 0H6V6H0z"></path>
      <path d="M8 0H14V6H8z"></path>
      <path d="M8 8H14V14H8z"></path>
      <path d="M0 8H6V14H0z"></path>
    </svg>
  )
}

export default GridIcon
