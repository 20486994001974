import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import InstaIcon from "../icons/instagram-2"
import { gsap } from "gsap"
import useEffectSkipComponentMount from "../lib/useEffectSkipComponentMount"
import DarkToggle from "./darkModeTogglev2"
import TriangleLeft from "../icons/triangleLeft"
import TriangleRight from "../icons/triangleRight"
import GridIcon from "../icons/gridIcon"
import SquareIcon from "../icons/squareIcon"

const Header = ({ navLinks, toggleIsGrid, navInstruction, isGrid }) => {
  // Nav open or not state
  const [isOpen, setIsOpen] = useState(false)
  const navBackground = useRef(null)
  const navLinkList = useRef(null)
  const nav = useRef(null)
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false)

  const toggleAnimationInProgress = () => {
    setIsAnimationInProgress(!isAnimationInProgress)
  }

  const menuToggle = () => {
    // if an animation is in progress then don't start another
    if (isAnimationInProgress) return
    toggleAnimationInProgress()
    setIsOpen(!isOpen)
  }

  const InternalLink = ({ link, linkTitle }) => (
    <Link
      to={`/${link}`}
      className="mr-6 font-medium lg:mr-10 xl:mr-14 hover-underline-black"
      activeClassName="link-underline"
    >
      {linkTitle}
    </Link>
  )

  const ExternalLink = ({ link, linkTitle }) => (
    <a
      href={`${link}`}
      className="mr-6 font-medium lg:mr-10 xl:mr-14 hover-underline-black"
      target="_blank"
      rel="noreferrer"
    >
      {linkTitle}
    </a>
  )

  const openNavAnimation = () => {
    const openNav = gsap.timeline({ onComplete: toggleAnimationInProgress })
    openNav
      //
      .set(nav.current, {
        x: "100vw",
      })
      .to(navBackground.current, {
        x: 0,
        duration: 1,
      })
      .to(
        navLinkList.current.children,
        {
          x: 32,
          opacity: 1,
          duration: 0.8,
          stagger: {
            amount: 0.5,
          },
        },
        "<"
      )
  }

  const closeNavAnimation = () => {
    const closeNav = gsap.timeline({ onComplete: toggleAnimationInProgress })
    closeNav
      .to(navLinkList.current.children, {
        opacity: 0,
        duration: 0.5,
        stagger: {
          amount: 0.5,
          from: "end",
        },
      })
      .to(
        navLinkList.current.children,
        {
          x: 0,
          duration: 0.8,
          stagger: {
            amount: 0.5,
            from: "end",
          },
        },
        "<"
      )
      .to(
        navBackground.current,
        {
          x: "-100vw",
          duration: 1,
        },
        "<"
      )
      .set(nav.current, {
        x: "0%",
        duration: 0,
      })
  }

  useEffectSkipComponentMount(() => {
    if (isOpen) {
      openNavAnimation()
    } else {
      closeNavAnimation()
    }
  }, [isOpen])

  return (
    <header className="fixed z-10 flex justify-between w-full px-8 pt-12px">
      <button
        className={`hamburger hamburger--collapse relative ${
          isOpen && "is-active"
        }`}
        type="button"
        onClick={menuToggle}
        aria-label="toggle menu open or closed"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <div className="nav-background background" ref={navBackground} />
      <nav className="z-30 nav text" ref={nav}>
        <ul ref={navLinkList}>
          {navLinks.map(each =>
            each.__typename === "ContentfulLinkInternal" ? (
              <li key={`link-${each.id.slice(0, 8)}`}>
                <InternalLink
                  link={each.link || ""}
                  linkTitle={each.linkTitle}
                />
              </li>
            ) : (
              <li key={`link-${each.id.slice(0, 8)}`}>
                <ExternalLink
                  link={each.link || ""}
                  linkTitle={each.linkTitle}
                />
              </li>
            )
          )}
          <li key="dark-mode-toggle" className="dark-toggle">
            <DarkToggle />
          </li>
        </ul>
      </nav>
      <div className="items-center justify-center hidden md:flex">
        <button
          onClick={() => navInstruction("left")}
          value="left"
          aria-label="previous slide"
        >
          <TriangleLeft className="mx-4 text" />
        </button>
        <button onClick={toggleIsGrid} aria-label="toggle grid view">
          {isGrid ? (
            <SquareIcon className="mx-4 text grid-icon-size" />
          ) : (
            <GridIcon className="mx-4 text" />
          )}
        </button>
        <button
          onClick={() => navInstruction("right")}
          value="right"
          aria-label="next slide"
        >
          <TriangleRight className="mx-4 text" />
        </button>
      </div>
      <div className="items-center justify-center hidden md:flex">
        <InstaIcon className="insta-icon" />
      </div>
    </header>
  )
}

Header.propTypes = {
  navLinks: PropTypes.array.isRequired,
  instagramLink: PropTypes.string,
  toggleIsGrid: PropTypes.func,
  navInstruction: PropTypes.func,
  isGrid: PropTypes.bool,
}

export default Header
